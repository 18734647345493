import React from "react";
import { graphql } from "gatsby";

import Layout from "../components/layout";
import SEO from "../components/seo";
import RowsProducts from '../components/home/RowsProducts';
import { Row } from '../components/layout/grid'
import { Button } from '../components/layout/form'

import { loadProduct } from "../utils/actions/product";
import { formatData } from "../utils/tools";

// import "../assets/css/404.css"
// const api = '127.0.0.1/gatsby-woo'
const api = process.env.WC_API_DEPLOY_URL ||  'store.aalladine.com'

class NewProductsPage extends React.Component {

  state = {
    products: [],
    loading: false,
    page: 11,
    therIsMoreToLoad: true,
  }

  /**
   * @name onClick
   * fired when user click loading button
   */
  onClick = async e => {
    e.preventDefault();
    this.setState({loading: true});
    try {
      let oldProducts = await loadProduct(`https://${api}/wp-json/wc/v3/products?page=${this.state.page}`);
      this.setState(prevState => ({
        products: [...prevState.products, ...oldProducts ],
        page: prevState.page+1,
        loading: false,
        therIsMoreToLoad: oldProducts.length < 10? false : true
      }));
    } catch (e) {
      this.setState({loading: false});
      alert('Verifiez Votre connexion internet/données ou ressayez s.v.p');
    }
  }

  componentDidMount(){
    let { allWcProducts: products } = this.props.data;
    products = products.edges.map( ({node: product} ) => product);
    this.setState({ products });
  }

  render(){
    let { loading, products, therIsMoreToLoad } = this.state;
    let categoriesKeys = this.props.data.allWcProductsCategories.edges.map(({ node: category }) => category.name);

    products = formatData(products, 4);
    return (
      <Layout
        navigate={this.props.navigate}
        categories={this.props.data.allWcProductsCategories || {edges: []}}>
        <SEO 
          title="Nos Derniers Produits"
          keywords={[`aalladine`, `ecommerce boutique`, ...categoriesKeys]} />
        <div className="page-new-products">
          <RowsProducts data={products} title='Nouveaux Produits' />
          <Row className="page-new-products__load-button-container center">
            {
              therIsMoreToLoad?
                (
                  <Button
                    type='button'
                    onClick={this.onClick}
                    className="page-new-products__load-button"
                    disabled={loading}
                  >
                      <span className="page-new-products__button-text">
                        {
                          !loading ?
                          ('Charger plus de produits') :
                          (
                            <>
                              Charegement <span className="dots-anime"></span>
                            </>
                          )
                        }
                      </span>
                  </Button>
                ):
                (<></>)
            }
          </Row>
        </div>
      </Layout>
    )
  }
}

export default NewProductsPage;

export const pageQuery = graphql`
  query {
    allWcProducts (
      sort: { fields: [ date_created_gmt ], order: DESC },
     ) {
      edges {
        node {
          id
          total_sales
          name
          slug
          price
          regular_price
          average_rating
          rating_count
          _links{
            self{
              href
            }
          }
          acf {
            language
            brand
          }
          images {
            src
            id
          }
        }
      }
    }
    allWcProductsCategories (
      filter : { slug : {nin: ["uncategorized", "non-classe"]} },
      sort: { fields: [count], order: DESC }
    ) {
      edges {
       node {
         id
         display
         name
         slug
         count
         description
         _links {
           self {
             href
           }
         }
         image{
           id
           src
           name
           alt
         }
       }
      }
    }
  }
`
